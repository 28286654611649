import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import CTA from 'components/CTA';
import { linkGold, linkOrders, linksGold, linksS } from './links';

import mobileLogo from '../../assets/logo_name.png';
import hamburger from 'assets/hamburger.svg';
import closeIcon from 'assets/closeIcon.svg';


import styles from './hamburgerMenu.module.scss';
import PopUp from '../../components/PopUp';
import ProfileInfo from '../PopUp/components/ProfileInfo';
import useStore from 'store';
import { useSelector } from 'react-redux';
import AddSupplier from '../PopUp/components/AddSupplier/AddSupplier';
import { MenuLink } from './MenuLink';
import { LinkMenu } from './LinkMenu';
import { usePopUp } from '../../hooks/usePopUp';
import Closesession from '../PopUp/components/CloseSesion/Closesession';
import { Typography } from '@mui/material';




const HamburgerMenu = () => {
  const { user } = useSelector((state) => state.auth)
  const [showModal, setShowModal] = useState(false);
  const { show, setShow } = usePopUp()
  const navigate = useNavigate();
  const [clickedCard, setClickedCard] = useState(null);
  const [showModalUser, setShowModalCli] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const { pathname } = useLocation();

  return (
    <>
      <Navigation setIsOpen={setIsOpen} isOpen={isOpen} />
      <PopUp setShowModal={setShow} showModal={show}>
        <Closesession
          setShowModal={setShow}
        />
      </PopUp>
      <PopUp setShowModal={setShowModal} showModal={showModal}>
        <ProfileInfo
          clickedCard={clickedCard}
          user={user}
          setShowModal={setShowModal}
          local={[]}
        />
      </PopUp>
      <PopUp setShowModal={setShowModalCli} showModal={showModalUser}>

        <AddSupplier setRerender={false} setShowModal={setShowModalCli} />
      </PopUp>
      <div className={styles.header}>
        <div
          onClick={() => {
            setIsOpen(true);
          }}
          className={styles.clickable}
        >
          <img src={hamburger} className={styles.hamburger} alt="hamburger" />
          <img src={mobileLogo} className={styles.logo} alt="cado logo" />
        </div>
        {pathname === '/catalogo' && (
          <div className={styles.ctaWrapper}>
            <CTA
              isDisabled={user?.secondaryRole === "ASESOR" ? true : false}
              text="Añadir Productos"
              size="small"
              onClick={() => {
                navigate('/catalogo/agregar_productos')
              }}
            />
          </div>
        )}
        {pathname === '/clientes' && (
          <div className={styles.ctaWrapper}>
            <CTA

              text="Añadir Cliente"
              size="small"
              onClick={() => {

                setShowModalCli(true);
              }}
            />
          </div>
        )}
        {pathname === '/usuarios' && (
          <div className={styles.ctaWrapper}>
            <CTA

              text="Añadir Usuario"
              size="small"
              onClick={() => {
                setClickedCard('usersByOwner');
                setShowModal(true);
              }}
            />
          </div>
        )}
      </div>
    </>
  );
};

const Navigation = ({ isOpen, setIsOpen }) => {
  const { user, subscription } = useSelector((state) => state.auth)

  // const user = useStore((state) => state.user);
  return (
    <div className={`${styles.wrapper} ${isOpen ? styles.open : ''}`}>
      
      <div className={styles.container}>
        <img
          src={closeIcon}
          className={styles.closeIcon}
          onClick={() => setIsOpen(false)}
        />

        {
          !user ? 
    
          <Link
          to={linkOrders[0].to}
          className={styles.link}
          onClick={() => setIsOpen(false)}
         
        >
          <img src={linkOrders[0].icon} className={styles.icons} />
          {linkOrders[0].name}
        </Link> :


          !subscription || subscription.plan.abreviacion === "base"  ?
            linksS.map((link, i) => {

              return (
                <Link
                  to={link.to}
                  key={`${i}_${link.to}`}
                  className={styles.link}
                  onClick={() => setIsOpen(false)}
                  // style={{
                  //   display:
                  //   (link.name === 'Órdenes' || link.name === 'Perfil' || link.name === 'Cerrar sesión' || link.name === 'Catálogo' || link.name === 'Clientes' || link.name === 'Mensajes') && !user  ? 'none' : user?.roles && link.name === 'Iniciar sesión' ? 'none'
                  //         : null,
                  // }}
                  style={{
                    display:
                      (link.name === 'Órdenes' || link.name === 'Perfil' || link.name === 'Cerrar sesión') && !user  ? 'none' : user?.roles && link.name === 'Iniciar sesión' ? 'none'
                          : null,
                  }}
                >
                  <img src={link.icon} className={styles.icons} />
                  {link.name}
                </Link>
              );
            })
            :
            linksGold.map((link, i) => {
              if (user.secondaryRole === 'BODEGA' && link.name != 'Perfil' &&  link.name != 'Órdenes' &&  link.name != 'Cerrar sesión') {

                return null

              }

              if (link.list.length === 0) {

                return (
                  (
                    <MenuLink

                      key={i}
                      to={link.to}
                      name={link.name}
                      icon={link.icon} />
                  )

                )

              } else {

                return (
                  <LinkMenu
                    to={link.to}
                    key={i}
                    icon={link.icon}
                    links={link.list}
                    name={link.name}
                  >
                  </LinkMenu>
                )
              }
            }
            )
        }
      </div>
    </div>
  );
};

Navigation.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  setIsOpen: PropTypes.func.isRequired,
};

export default HamburgerMenu;
