

import React, { useEffect, useState } from 'react'

import { useNavigate } from 'react-router-dom';
import { HomeLayouts } from '../../components/layouts/HomeLayout';
import DesktopQuotes from './components/DesktopQuotes';
import moment from 'moment';
import styles from './myOrders.module.scss';
import { quoteBySsupplierId } from '../../api/quotes';
import { useSelector } from 'react-redux';
import Loading from '../../components/Loading/Loading';
import SearchBar from '../../components/SearchBar/SearchBar';
import { Box, TablePagination } from '@mui/material';
const options = [15, 10, 5];
export const Quotes = () => {
  const { user, token, subscription } = useSelector((state) => state.auth)
  const [quotes, setquotes] = useState(null)
  const navigate = useNavigate();
  const [page, setpage] = useState(0)
  const [selectedTab, setSelectedTab] = useState(0);
  const [loading, setLoading] = useState(false);
  const [params, setParams] = useState({
    page: 1,
    limit: 10,
    status: 'Solicitada,Completada',
    query: '',
  });

  useEffect(() => {

    setQuotes()

  }, [])

  useEffect(() => {

    setQuotesPage()

  }, [params])

  const handleChangePage = (event, newPage) => {

    setpage(newPage);
    setParams({ ...params, page: newPage + 1 })

  };

  const handleChangeRowsPerPage = (event) => {
    setpage(0)
    setParams({ ...params, page: 1, limit: parseInt(event.target.value, 10) });

  };

console.log(quotes)

  const setQuotes = async () => {
    setLoading(true)

    const resp = await quoteBySsupplierId(token, user.id, user?.supplierId, params.status, params.limit, params.page)

    if (resp.ok) {
      setquotes(resp.data)
      setLoading(false)
    } else {
      setquotes([])
      setLoading(false)
    }
  }

  const setQuotesPage = async () => {
    //setLoading(true)

    const resp = await quoteBySsupplierId(token, user.id, user?.supplierId, params.status, params.limit, params.page)

    if (resp.ok) {
      setquotes(resp.data)

    } else {
      setquotes([])

    }
  }


  if (loading) return <HomeLayouts><Loading /></HomeLayouts>


  return (
    <HomeLayouts>
      <div>

        <div className={styles.content}>
          <div className={styles.header}>
            <div>
              <h1 className={styles.title}>Mis Cotizaciones</h1>
            </div>
          </div>

          <div className={styles.searchAndFilter}>
            <SearchBar
              query={params.query}
              setQuery={(val) => {
                setParams({ ...params, query: val });
              }}
              placeholder="Buscar en Mis Órdenes"
              name="query"
            />
            {/* <Filter /> */}
          </div>


          <div className={styles.statusTab}>

            <div
              className={`${styles.status} ${selectedTab === 0 && styles.active}`}
              onClick={() => {
                //dispatch(clearParamsO())
                setpage(0)
                setSelectedTab(0);
                setParams({ ...params, page: 1, status: 'Solicitada,Completada' });
              }}
            >
              Solicitadas
            </div>
            <div
              className={`${styles.status} ${selectedTab === 1 && styles.active}`}
              onClick={async () => {
                //dispatch(clearParamsO())
                setpage(0)
                setSelectedTab(1);
                setParams({ ...params, page: 1, status: 'Aceptada,Rechazada' });
              }}
            >
              Completadas
            </div>
          </div>
          <div className={styles.ordersContainer}>
            {loading ? (
              <Loading />
            ) : (
              quotes && quotes.quotes.map((order, i) => {
               
                return (
                  <div
                    key={`${i}_${order.id}`}
                    className={styles.orderWrapper}
                    onClick={(e) => {
                      navigate(`/cotizaciones/detalle_cotizacion/${order.id}`, { state: { order } });
                    }}
                  >
                    <div className={styles.order}>
                      <div className={styles.orderInformation}>
                        <p className={styles.supplier}>{order.nameSupplier}</p>
                        <p className={styles.date}>
                          {moment(order.createdAt).format('DD-MM-YYYY')}
                        </p>
                        <label className={`${styles.label} ${styles[order.status]}`}>
                          {order.status.charAt(0).toUpperCase() + order.status.slice(1)}
                        </label>
                      </div>

                    </div>

                  </div>
                );
              })
            )}
          </div>
          {
            !quotes ? null :
              <DesktopQuotes
                subscription={subscription}
                orders={quotes.quotes}
                params={params}
                setParams={setParams}
                reorder={() => { }}
                navigateToOrder={() => { }}
              />
          }

        </div>
        <Box sx={{ mr: { xs: 0, md: 35 } }} >
          <TablePagination
            component="div"
            labelRowsPerPage='Filas por página'
            rowsPerPageOptions={options}
            count={ !quotes ? 10 : quotes.totalCount}
            page={page}
            onPageChange={handleChangePage}
            rowsPerPage={parseInt(params.limit)}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />

        </Box>

      </div>
    </HomeLayouts>
  )
}
