import React from 'react';
import { Routes, Route } from 'react-router-dom';


import NotFound from 'router/components/NotFound';

import { Quotes } from './Quotes';
import { QuotesDetail } from './components/QuotesDetail';
import { QuotesConfirmation } from './QuotesConfirmation';

const QuotesRoute = () => {
  return (
    <>
      <Routes>
        <Route path={'/'} element={<Quotes />} />
        <Route path={'/confirmacion_cotizacion/:id'} element={<QuotesConfirmation />} />   
        <Route path={'/detalle_cotizacion/:id'} element={<QuotesDetail />} />        
        <Route path="*" element={<NotFound />} />
      </Routes>
    </>
  );
};

export default QuotesRoute;
