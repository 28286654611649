import React, { lazy, useEffect } from 'react';
import { Routes, Route, Navigate, useNavigate, useLocation } from 'react-router-dom';
import PrivateRoute from './components/PrivateRoute';

import routes from './routeList';
import Login from '../views/Login'
import Registration from '../views/Registration'
import RecoverPassword from '../views/RecoverPassword'
import Logout from '../views/Logout'
import Orders from '../views/Orders'
import NotFound from './components/NotFound';
import Profile from '../views/Profile'
import useStore from 'store';

import OrderDetails from '../views/Orders/components/OrderDetails';
import subRoutes from '../views/Orders/subRoutes';
import RecieveOrder from '../views/Orders/components/RecieveOrder';
import OrderConfirmation from '../views/Orders/components/OrderConfirmation';
import { useSelector } from 'react-redux';
import useAuth from '../hooks/useAuth';
import { Catalog } from '../views/Catalog/Catalog';
import CatalogRoutes from '../views/Catalog/CatalogoRoutes';
import { Users } from '../views/Users/Users';
import { DetailDeliv } from '../views/DetailDelivery/DetailDeliv';
import Password from '../views/Password/Password';
import { Customer } from '../views/Customer/Customer';
import CustomerRoutes from '../views/Customer/CustomerRoutes';
import { Categories } from '../views/Categories/Categories';
import { MessagesRouters } from '../views/messages/MessagesRouters';
import Loading from '../components/Loading/Loading';
import QuotesRoute from '../views/Quotes/QuotesRoute';



const AppRouter = () => {
  const { verifi } = useAuth()
  const { user, subscription, status } = useSelector((state) => state.auth)
  const navigate = useNavigate();
  const location = useLocation();





  if (status === 'cheking') {
    return <Loading />
  }



  if (location.pathname.includes('/ordenes/detalle/') || location.pathname.includes('/ordenes/recibir/') || location.pathname.includes('/ordenes/confirmacion/') ) {



    return (
      <Routes>
         <Route path={'ordenes/detalle/:id/:restaurant'} element={<OrderDetails />} />
         <Route path={'ordenes/recibir/:id/:restaurant'} element={<RecieveOrder />} />
         <Route path={'ordenes/confirmacion/:id/:restaurant'} element={<OrderConfirmation />} />
     </Routes>
    )
  }




  if (status === 'not-complete') {
    return (
      <Routes>

        <Route path={`${routes.resetPassword}/*`} element={<Password />} />
        <Route path={routes.logout} element={<Logout />} />
        <Route path={'/*'} element={<Navigate to={routes.resetPassword} />} />
      </Routes>
    )
  }




  if (status === 'not-authenticated'|| status === undefined ) {

    return (
      <Routes>
        <Route path={routes.login} element={<Login />} />
        <Route path={`${routes.registration}/*`} element={<Registration />} />
        <Route path={`${routes.recoverPassword}/*`} element={<RecoverPassword />} />
        <Route path="/*" element={<Navigate to={routes.login} />} />
      </Routes>
    )
  }

  if (status === 'authenticated') {

    return (
      <Routes>
        <Route path={`${routes.orders}/*`} element={<Orders />} />
        <Route path={`${routes.cotizaciones}/*`} element={<QuotesRoute />} />        
        <Route path={`${routes.catalog}/*`} element={<CatalogRoutes />} />
        <Route path={`${routes.category}/*`} element={<Categories />} />
        <Route path={`${routes.customers}/*`} element={<CustomerRoutes />} />
        <Route path={`${routes.profile}/*`} element={<Profile />} />
        <Route path={`${routes.resetPassword}/*`} element={<Password />} />
        <Route path={`${routes.messages}/*`} element={<MessagesRouters />} />
        <Route path={`${routes.users}/*`} element={<Users />} />
        <Route path={`${routes.detail}/*`} element={<DetailDeliv />} />
        <Route path={routes.logout} element={<Logout />} />
        <Route path={'/*'} element={<Navigate to={routes.orders} />} />
      </Routes>

    )



  } else {

    return (
      <Routes>
        <Route path={routes.login} element={<Login />} />
        <Route path={`${routes.registration}/*`} element={<Registration />} />
        <Route path={`${routes.recoverPassword}/*`} element={<RecoverPassword />} />
        <Route path={routes.logout} element={<Logout />} />
        <Route path="/*" element={<Navigate to={routes.login} />} />

      </Routes>


    )

  }





  // if (user && user.complete === false) {
  //   return (
  //     <Routes>

  //       <Route path={`${routes.resetPassword}/*`} element={<Password />} />
  //       <Route path={routes.logout} element={<Logout />} />
  //       <Route path={'/*'} element={<Navigate to={routes.resetPassword} />} />
  //     </Routes>
  //   )
  // }



  // if (user?.role === 'guestUser' && location.pathname.includes('/ordenes/detalle/') || user?.role === 'guestUser' && location.pathname.includes('/ordenes/recibir/') || user?.role === 'guestUser' && location.pathname.includes('/ordenes/confirmacion/')) {

  //   return (

  //     <Routes>

  //       <Route path={'ordenes/detalle/:id/:restaurant'} element={<OrderDetails />} />
  //       <Route path={'ordenes/recibir/:id/:restaurant'} element={<RecieveOrder />} />
  //       <Route path={'ordenes/confirmacion/:id/:restaurant'} element={<OrderConfirmation />} />

  //     </Routes>

  //   )

  // }

  // if (!user && !location.pathname.includes('/ordenes/detalle/') || user?.role === 'guestUser' && !location.pathname.includes('/ordenes/detalle/')) {



  //   return (
  //     <Routes>
  //       <Route path={routes.login} element={<Login />} />
  //       <Route path={`${routes.registration}/*`} element={<Registration />} />
  //       <Route path={`${routes.recoverPassword}/*`} element={<RecoverPassword />} />
  //       <Route path={routes.logout} element={<Logout />} />
  //       <Route path="/*" element={<Navigate to={routes.login} />} />

  //     </Routes>
  //   )
  // }

  // if (user && user?.role != 'guestUser') {

  //   if (!subscription || subscription.plan.abreviacion === 'base') {

  //     return (
  //       <Routes>
  //         <Route path={`${routes.orders}/*`} element={<Orders />} />
  //         <Route path={`${routes.profile}/*`} element={<Profile />} />
  //         <Route path={`${routes.resetPassword}/*`} element={<Password />} />
  //         <Route path={`${routes.detail}/*`} element={<DetailDeliv />} />
  //         <Route path={routes.logout} element={<Logout />} />
  //         <Route path={'/*'} element={<Navigate to={routes.orders} />} />
  //       </Routes>
  //     )

  //   } else {
  //     return (

  //       <Routes>
  //         <Route path={`${routes.orders}/*`} element={<Orders />} />
  //         <Route path={`${routes.catalog}/*`} element={<CatalogRoutes />} />
  //         <Route path={`${routes.category}/*`} element={<Categories />} />
  //         <Route path={`${routes.customers}/*`} element={<CustomerRoutes />} />
  //         <Route path={`${routes.profile}/*`} element={<Profile />} />
  //         <Route path={`${routes.resetPassword}/*`} element={<Password />} />
  //         <Route path={`${routes.messages}/*`} element={<MessagesRouters />} />
  //         <Route path={`${routes.users}/*`} element={<Users />} />
  //         <Route path={`${routes.detail}/*`} element={<DetailDeliv />} />
  //         <Route path={routes.logout} element={<Logout />} />
  //         <Route path={'/*'} element={<Navigate to={routes.orders} />} />
  //       </Routes>

  //     )

  //   }

  // } else {

  //   return null

  // }




}

export default AppRouter;
