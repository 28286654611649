import React, { useState, useEffect } from 'react';

import { getCurrentSupplier } from 'api/suppliers';

import useStore from 'store';
import PopUp from 'components/PopUp';
import ProfileInfo from 'components/PopUp/components/ProfileInfo';

import styles from './profile.module.scss';
import { HomeLayouts } from '../../components/layouts/HomeLayout';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Grid } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { getUsers } from '../../redux/usersSlice/thunk';
import Loading from '../../components/Loading/Loading';
import { useSup } from '../../hooks/useSup';

const Profile = () => {
  const { user, subscription, token } = useSelector((state) => state.auth)
  const { admin, asesor, isLoadin } = useSelector((state) => state.users)
  const dispatch = useDispatch()
  const [showModal, setShowModal] = useState(false);
  const [clickedCard, setClickedCard] = useState(null);
  const [supplier, setSupplier] = useState(null);
  const navigate = useNavigate();
  const { } = useSup()

  useEffect(async () => {
    dispatch(getUsers(user?.supplierId, token))
    // const data = await getCurrentSupplier();
    // setSupplier(data);
  }, []);

  const methodObject = {
    phone: 'Teléfono',
    email: 'Correo',
  };

  if (isLoadin) {
    return <HomeLayouts>
      <Loading />

    </HomeLayouts>;
  }

  return (

    <HomeLayouts>


      <div className={styles.content}>
        <PopUp showModal={showModal} setShowModal={setShowModal}>
          <ProfileInfo
            subscription={subscription}
            clickedCard={clickedCard}
            user={user}
            setShowModal={setShowModal}
          />
        </PopUp>
        <div className={styles.desktopHeader}>Perfil</div>

        <Grid
          container
          gap={1}
        >
          <Grid md={5.5} xs={12} item>
            <div
              className={styles.card}
              onClick={() => {
                setClickedCard('user');
                setShowModal(true);
              }}
            >
              <div className={styles.cardTitle}>Datos del Usuario</div>
              <div className={styles.cardField}>
                <div className={styles.label}>Nombre: </div>
                <div className={styles.value}>{user.name}</div>
              </div>
              <div className={styles.cardField}>
                <div className={styles.label}>Teléfono: </div>
                <div className={styles.value}>{user.phone}</div>
              </div>
            </div>
          </Grid>
          {
            user?.secondaryRole === "BODEGA" ? null :
              <Grid md={5.5} xs={12} item>
                <div
                  className={styles.card}
                  onClick={() => {
                    setClickedCard('subcription');
                    setShowModal(true);
                  }}
                >
                  <div className={styles.cardTitle}>Suscripción</div>
                  <div className={styles.cardField}>
                    <div className={styles.label}>Facturación: </div>
                    <div className={styles.value}>
                      {subscription
                        ? subscription?.plan?.interval.charAt(0).toUpperCase() +
                        subscription?.plan?.interval.slice(1)
                        : 'N/A'}
                    </div>
                  </div>
                  <div className={styles.cardField}>
                    <div className={styles.label}>Estado: </div>
                    {subscription?.status === 'activa' ? (
                      <div className={`${styles.statusLabel} ${styles.active}`}>Activo</div>
                    ) : (
                      <div className={`${styles.statusLabel} ${styles.inactive}`}>Inactivo</div>
                    )}
                  </div>
                </div>
              </Grid>
          }


          {
            user?.secondaryRole === "ASESOR" || user?.secondaryRole === "BODEGA" || !subscription || subscription.plan.abreviacion === "base" ? null :


              <Grid md={5.5} xs={12} item >
                <div
                  className={styles.card}
                  onClick={() => {
                    navigate(`/usuarios`);
                  }}
                >
                  <div className={styles.cardTitle}>Usuarios</div>
                  <div className={styles.cardField}>
                    <div className={styles.label}>Admin: </div>
                    <div className={styles.value}>{admin} </div>
                  </div>
                  <div className={styles.cardField}>
                    <div className={styles.label}>Asesores: </div>
                    <div className={styles.value}>{asesor} </div>
                  </div>
                </div>
              </Grid>
          }

          {
            user?.secondaryRole === "BODEGA" ? null :
              <Grid md={5.5} xs={12} item>
                <div
                  className={styles.card}
                  onClick={() => {
                    setClickedCard('contact');
                    setShowModal(true);
                  }}
                >
                  <div className={styles.cardTitle}>Preferencia de Contacto</div>
                  <div className={styles.cardField}>
                    <div className={styles.label}>Método: </div>
                    <div className={styles.value}>{methodObject[user.contactMethod]}</div>
                  </div>
                  <Box padding={1.9} ></Box>
                </div>
              </Grid>
          }

          {
            user?.secondaryRole === "BODEGA" ? null :

              <Grid md={5.5} xs={12} item>
                <div
                  className={styles.card}
                  onClick={() => {
                    setClickedCard('advertisement');
                    setShowModal(true);
                  }}
                >
                  <div className={styles.cardTitle}>Anuncio para Clientes</div>
                  <div className={styles.cardField}>
                    <div className={styles.value}> Las órdenes confirmadas se entregarán los días martes, miércoles y sábado </div>
                  </div>

                </div>
              </Grid>
          }

          {
            subscription.plan.abreviacion === "enterprise" & user?.secondaryRole === "ADMIN" ?

              <Grid md={5.5} xs={12} item>
                <div
                  className={styles.card}
                  onClick={() => {
                    setClickedCard('credentials');
                    setShowModal(true);
                  }}
                >
                  <div className={styles.cardTitle}>API KEY</div>
                  <div className={styles.cardField}>
                    <div className={styles.value}> Aqui puedes ingresar la API KEY de Contifico.</div>
                  </div>

                </div>
              </Grid>

              : null
          }


          {/* <Grid md={5.5} xs={12} item>
            <div
              className={styles.card}
              onClick={() => {
                navigate(`/detalle`);
              }}
            >
              <div className={styles.cardTitle}>Detalles de Entrega</div>
              <div className={styles.cardField}>
                <div className={styles.label}>Orden mínima: </div>
                <div className={styles.value}> $50 </div>
              </div>
              <div className={styles.cardField}>
                <div className={styles.label}>Días de entrega: </div>
                <div className={styles.value}> Lun, Mar, Mier, Jue, Vie </div>
              </div>
            </div>
          </Grid> */}
        </Grid>
      </div>
    </HomeLayouts>

  );
};

export default Profile;
