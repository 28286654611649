import React from 'react';
import PropTypes from 'prop-types';
import QuantityModifier from '../QuantityModifier';
import QuantityAmount from '../QuantityAmount';
import styles from './productReview.module.scss';
import QuantityModifierOrd from '../QuantityModifierOrd/QuantityModifierOrd';
import { Box, Divider, Grid, Typography } from '@mui/material';

const ProductReview2 = ({ newOrder, allowChanges, state, setState, shouldRemoveEmpty = true }) => {



  return (
    <div className={styles.container}>
      {state.products.map((product, i) => {
  
        return (
          <Box
            key={i}
          >

            <Box p={1.3} display={'flex'} flexDirection={'row'} justifyContent={'space-between'} alignItems={'center'} >
              <Box sx={{ width:{ xs:180, md:170, xl: 300, lg:180, sm:140} }}  >
                <Typography fontWeight={'bold'} sx={{ fontSize: { xs: 13, md: 14 } }} >
                  
                  {product.productName === undefined ? product.name : product.productName}
                </Typography>

              </Box>
              
              {allowChanges && newOrder === undefined ? (
                <QuantityModifier
                  product={product}
                  state={state}
                  setState={setState}
                  allowChanges={allowChanges}
                  quantityKey={
                    typeof product.confirmedQuantity === 'number'
                      ? 'confirmedQuantity'
                      : 'requestedQuantity'
                  }
                  shouldRemoveEmpty={shouldRemoveEmpty}
                />
              ) : allowChanges && newOrder ?

                (
                  <QuantityModifierOrd
                    product={product}
                    state={state}
                    setState={setState}
                    allowChanges={allowChanges}
                    quantityKey={
                      typeof product.confirmedQuantity === 'number'
                        ? 'confirmedQuantity'
                        : 'requestedQuantity'
                    }
                    shouldRemoveEmpty={shouldRemoveEmpty}
                  />
                ) :
                (
                  <QuantityAmount product={product} />
                )}

            </Box>
          
            {i === state.products.length - 1 ? null :
              <Divider color='#cbc7c7' sx={{ opacity: 0.3 }} />

            }
          </Box>


        );
      })}
    </div>
  );
};

ProductReview2.propTypes = {
  allowChanges: PropTypes.bool.isRequired,
  state: PropTypes.object.isRequired,
  setState: PropTypes.func.isRequired,
  origin: PropTypes.string,
  shouldRemoveEmpty: PropTypes.bool,
};

export default ProductReview2;
