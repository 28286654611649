import React from 'react'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getSubscription, getUser } from '../api/user'
import { setUser } from '../redux/authSlice/authSlice'
import toast from 'react-hot-toast'

export const useSup = () => {
  const { user, token, subscription } = useSelector((state) => state.auth)
     const dispatch = useDispatch()


    useEffect(() => {
        getInfo()
    }, [])
    
  


    const getInfo = async ()=>{


        try {
                    
              const userf = await getUser( token, user?.phone);
                const subscription = await getSubscription(user?.id, token )
                dispatch(setUser( {user:userf, status:'authenticated',  subscription:subscription.data } ))
            
        } catch (error) {
            if (error.response) {
                const errors = getArrayOfErrors(error.response.data);
                for (let i = 0; i < errors.length; i++) {
                    toast.error(errors[i]);
                }
            } else {
                toast.error('Error interno. Inténtelo más tarde');
            }
        }
    }
    
    return {

  }
}
