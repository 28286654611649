import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-hot-toast';
import { AiOutlineLeft as Left } from '@react-icons/all-files/ai/AiOutlineLeft';
import { AiOutlineRight as Right } from '@react-icons/all-files/ai/AiOutlineRight';

import { getOrders } from 'api/orders';

import DesktopOrders from './DesktopOrders';
import SearchBar from 'components/SearchBar';
import Loading from 'components/Loading';
import { getDisplayDate } from 'utils/dates';

import { getArrayOfErrors } from 'utils/errors';

import styles from './myOrders.module.scss';
import { Box, Container, TablePagination, Typography } from '@mui/material';
import useAuth from '../../../../hooks/useAuth';
import { HomeLayouts } from '../../../../components/layouts/HomeLayout';
import { useDispatch, useSelector } from 'react-redux';
import { setErrorToken } from '../../../../redux/authSlice/authSlice';
import CTA from '../../../../components/CTA';
import { setStep } from '../../../../redux/newOrderSlice/nOrderSlice';

const MyOrders = () => {
  const { verifi } = useAuth()
  const dispatch = useDispatch()
  const { user, token, subscription } = useSelector((state) => state.auth)
  const options = [15, 10, 5];
  const [start, setStart] = useState(1);
  const [page, setpage] = useState(0)
  const navigate = useNavigate();
  const [selectedTab, setSelectedTab] = useState(0);
  const [orders, setOrders] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [params, setParams] = useState({
    page: 1,
    limit: 10,
    status: 'solicitada,aceptada',
    query: '',
  });
  const [end, setEnd] = useState(params.limit * params.page);
  const navigateToOrder = (e, id, restaurant) => {
    if (e.target.nodeName !== 'BUTTON') {
      navigate(`/ordenes/detalle/${id}/${restaurant}`);
    }
  };
  const handleChangePage = (event, newPage) => {

    setpage(newPage);
    setParams({ ...params, page: newPage + 1 })

  };

  const handleChangeRowsPerPage = (event) => {
    setpage(0)
    setParams({ ...params, page: 1, limit: parseInt(event.target.value, 10) });

  };




  useEffect(async () => {

    try {
      const response = await getOrders(params, user?.id, user?.supplierId, token);
      setOrders(response);
    } catch (error) {
      if (error.response) {
        const errors = getArrayOfErrors(error.response.data);

        if (error.response.data === 'Su token ha caducado por favor vuelve a iniciar sesión') {

          dispatch(setErrorToken(true))

        } else {
          for (let i = 0; i < errors.length; i++) {
            toast.error(errors[i]);
          }
        }



      } else {
        toast.error('Error interno. Inténtelo más tarde');
      }
    } finally {
      setIsLoading(false);
    }
  }, [params.page]);

  useEffect(async () => {
    try {
      const response = await getOrders({ ...params, page: 1 }, user?.id, user?.supplierId, token);
      setParams((params) => ({ ...params, page: 1 }));
      setOrders(response);
    } catch (error) {
      if (error.response) {
        const errors = getArrayOfErrors(error.response.data);

        if (error.response.data === 'Su token ha caducado por favor vuelve a iniciar sesión') {

          dispatch(setErrorToken(true))

        } else {
          for (let i = 0; i < errors.length; i++) {
            toast.error(errors[i]);
          }
        }



      } else {
        toast.error('Error interno. Inténtelo más tarde');
      }
    } finally {
      setIsLoading(false);
    }
  }, [params.limit, params.query, params.status]);



  if (!orders) return <HomeLayouts></HomeLayouts>;

  return (


    <HomeLayouts>
      <div>
        <div className={styles.content}>
          <div className={styles.header}>
            <div>
              <h1 className={styles.title}>Mis Órdenes</h1>
            </div>


            {
              subscription && subscription.plan.abreviacion === 'pro' ?


                <Box  >

                  <CTA
                    isDisabled={false}

                    text="Nueva Orden"
                    onClick={() => {
                      navigate('/ordenes/nueva');
                      dispatch(setStep({ step: 1, customer: '' }))
                    }}
                    size="small"
                  />

                </Box>


                :

                null

            }


          </div>
          <div className={styles.searchAndFilter}>
            <SearchBar
              query={params.query}
              setQuery={(val) => {
                setParams({ ...params, query: val });
              }}
              placeholder="Buscar en Mis Órdenes"
            />
          </div>
          <div className={styles.statusTab}>
            <div
              className={`${styles.status} ${selectedTab === 0 && styles.active}`}
              onClick={() => {
                setSelectedTab(0);
                setParams({ ...params, status: 'solicitada,aceptada' });
              }}
            >
              Entrantes
            </div>
            <div
              className={`${styles.status} ${selectedTab === 1 && styles.active}`}
              onClick={() => {
                setSelectedTab(1);
                setParams({ ...params, status: 'entregada,cancelada' });
              }}
            >
              Anteriores
            </div>
          </div>
          <div className={styles.ordersContainer}>
            {isLoading ? (
              <Loading />
            ) : (
              orders.orders.map((order, i) => {
                return (
                  <div
                    key={`${i}_${order.id}`}
                    className={order.view === false && order.status === 'solicitada' ? styles.orderWrapperV : styles.orderWrapper}
                    onClick={(e) => {
                      navigateToOrder(e, order.code, order?.restaurant?.id);
                    }}
                  >
                    <div className={styles.order}>
                      <div className={styles.orderInformation}>
                        <Box display='flex' alignItems='center' >
                          <p className={styles.supplier}>{order.restaurant.name} </p>

                          {order.view === false && order.status === 'solicitada' ?
                            <Box ml={0.5} borderRadius={50} bgcolor={'#E06469'} width={13} height={13} >  </Box>
                            :
                            null
                          }
                        </Box>

                        <p className={styles.date}>
                          {getDisplayDate(order.scheduleDate)} -{' '}
                          {order.status.charAt(0).toUpperCase() + order.status.slice(1)}
                        </p>
                        <label className={styles.date}> {order.code}</label>
                      </div>
                    </div>
                  </div>
                );
              })
            )}

          </div>
          <DesktopOrders
            orders={orders}
            params={params}
            setParams={setParams}
            navigateToOrder={navigateToOrder}
          />

        </div>
        <Box sx={{ mr: { xs: 0, md: 35 } }} >
          <TablePagination
            component="div"
            labelRowsPerPage='Filas por página'
            rowsPerPageOptions={options}
            count={orders.totalCount}
            page={page}
            onPageChange={handleChangePage}
            rowsPerPage={parseInt(params.limit)}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />

        </Box>

        {/* 
      <Container sx={{ backgroundColor:'red', mr:25, paddingTop: { xs: 1, md: 0 }, marginTop:{ md:-2 } }} maxWidth='lg' >
       
      </Container> */}






      </div>
    </HomeLayouts>



  );
};

export default MyOrders;
