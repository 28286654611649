import { setError, setPass, setToken, setUser, startAuth } from "./authSlice";
import { getSubscription, getUser } from "../../api/user";
import useStore from 'store';
import { login } from "../../api/auth";
import { useNavigate } from "react-router-dom";







export const auth =  (data)=>{

  return async (dispatch)=>{
  

    try {

      dispatch(startAuth())
      const token =  await login(data);
      

     
        if (token.data.app === 'supplier') {   
          dispatch(setToken( token.data.token ))
       
        const user = await getUser(token.data.token, data.phone );
        const subscription = await getSubscription(user?.id, token.data.token )

          if (!user.complete) {
            dispatch(setUser( {user, status:'not-complete', subscription:subscription.data } ))  
            dispatch(setPass(data.password))  
          }else{

            dispatch(setUser( {user, status:'authenticated', subscription:subscription.data } ))
            dispatch(setPass(data.password))
          }
       
       }else{
        dispatch(setError('Esta cuenta no esta registrada como proveedor')) 
      }
    } catch (error) {
   
        if (error.response.data === 'Error interno') {
         
          dispatch(setError('Error interno por favor intentalo más tarde'))

        }else{
          dispatch(setError(error.response.data))
        }
      
    }

  }

} 



export const me = ()=>{

  
  return async (dispatch)=>{
  

    try {
   
        const user = await getUser(token.data.token, data.phone );
    
            dispatch(setUser( {user, status:'get-user', subscription:null } ))  
               
   
    } catch (error) {

      
       
        if (error.response.data === 'Error interno') {
          
          dispatch(setError('Error interno por favor intentalo más tarde'))

        }else{
          dispatch(setError(error.response.data))
        }
      
    }

  }


}




