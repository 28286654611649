import axios from "axios";



export const quoteBySsupplierId = async (token, userId, supplierId, status='Solicitada', limit=10, page=1 )=>{

    
    const response = await axios.get( `${process.env.API_URL}/quotes/user/${userId}/supplier/${supplierId}?status=${status}&limit=${limit}&page=${page}`,
    {
     withCredentials:true,
    headers: {
        'Authorization': `Bearer ${token}`,
        'Accept': '*/*',
        'content-type': 'application/json'
        
    }
      });

      if (response.status === 200) {
            return { ok:true,  data: response.data}
      }else{
        return { ok:false,  data:[] }
      }

  }




export const quoteForId = async (token, id )=>{

    

    const response = await axios.get( `${process.env.API_URL}/quotes/${id}`,
    {
     withCredentials:true,
    headers: {
        'Authorization': `Bearer ${token}`,
        'Accept': '*/*',
        'content-type': 'application/json'
        
    }
      });

      if (response.status === 200) {
            return { ok:true,  data: response.data}
      }else{
        return { ok:false,  data:[] }
      }

  }