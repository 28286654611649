import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { quoteForId } from '../../api/quotes';
import styles from '../../styles/common/confirmation.module.scss'
import { HomeLayouts } from '../../components/layouts/HomeLayout';
import Loading from '../../components/Loading/Loading';
import CTA from '../../components/CTA';
import { getDisplayDate } from '../../utils/dates';

export const QuotesConfirmation = () => {
    const { user, token } = useSelector((state) => state.auth)
    const { id } = useParams();
    const [quote, setquote] = useState(null)
    const [details, setDetails] = useState(null)
    const [loading, setloading] = useState(true)
    const navigate = useNavigate();

    useEffect(() => {
        setQuote()
    }, [])

    // console.log(quote.anonymousRestaurant)

    const setQuote = async () => {


        const resp = await quoteForId(token, id)
        console.log(resp)
        if (resp.ok) {
            setquote(resp.data)
            const details = [
                {
                    label: 'Comercio: ',
                    value: resp.data.anonymousRestaurant ? 'Anonimo' : resp.data.nameRestaurant,
                },
                {
                    label: 'Fecha cotización:',
                    value: getDisplayDate(resp.data.createdAt),
                },
            ]
            setDetails(details)
            setloading(false)
        } else {
            setquote(resp.data)
            setloading(false)
        }

    }





    if (loading) return <HomeLayouts><Loading /></HomeLayouts>;



    return (



        <HomeLayouts>
            <div className={styles.content}>
                <div className={styles.mainContainer}>
                    <h1 className={styles.title}>¡Cotización Aceptada!</h1>
                    <h2 className={styles.subtitle}>
                        Has aceptado la cotización solicitada {quote.anonymousRestaurant ? null : `por ${quote.nameRestaurant}`}.
                    </h2>
                    <div className={styles.detailsContainer}>
                        <h3 className={styles.orderNumber}>Cotizacion # {quote.id}</h3>

                        {details.map((detail) => (
                            <div className={styles.detail} key={detail.label}>
                                <span className={styles.label}>{detail.label}</span>
                                <span className={styles.value}>{detail.value}</span>
                            </div>
                        ))}

                        {/* <span
                            className={styles.span}
                            onClick={() => {
                                navigate(`/ordenes/detalle/${id}/${restaurant}`);
                            }}
                        >
                            Ver Detalle
                        </span> */}
                    </div>
                </div>
                <div className={styles.ctaContainer}>
                    <CTA
                        text="Ir a Mis Cotizaciones"
                        isDisabled={false}
                        onClick={() => {
                            navigate('/cotizaciones');
                        }}
                    />
                </div>
            </div>
        </HomeLayouts>


    );
}
