export default {
  orders: '/ordenes',
  cotizaciones: '/cotizaciones',
  catalog: '/catalogo',
  messages: '/mensajes',
  customers: '/clientes',
  login: '/ingreso',
  logout: '/salir',
  category:'/categorias',
  users: '/usuarios',
  detail: '/detalle',
  profile: '/perfil',
  recoverPassword: '/contrasena',
  resetPassword: '/resetPassword',
  registration: '/registro',
};
