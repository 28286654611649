import React, { useEffect, useState } from 'react'
import { HomeLayouts } from '../../../components/layouts/HomeLayout';
import Loading from '../../../components/Loading/Loading';
import { Box, Grid, Input, InputAdornment, OutlinedInput, TextField, Typography } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import { IoArrowBackOutline as Back } from '@react-icons/all-files/io5/IoArrowBackOutline';
import { useSelector } from 'react-redux';
import { quoteForId, quoteUpdate } from '../../../api/quotes';
import moment from 'moment';
import CTA from '../../../components/CTA';
import CTARed from '../../../components/CTARed';
import PopUp from '../../../components/PopUp';
import CancelQuote from '../../../components/PopUp/components/CancelQuote/CancelQuote';
import { getDisplayDate } from '../../../utils/dates';
import toast from 'react-hot-toast';


export const QuotesDetail = () => {
    const { user, subscription, token, restaurantsSelect } = useSelector((state) => state.auth)
    const { id } = useParams();
    const [order, setorder] = useState(null)
    const [showModal, setShowModal] = useState(false);
    const [products, setproducts] = useState([])
    const [loading, setloading] = useState(false)
    const [loadingSend, setloadingSend] = useState(false)
    const [complet, setComplet] = useState(false)
    const [price, setPrice] = useState('')

    const navigate = useNavigate();



    useEffect(() => {

        setQuote()
    }, [])



    const setQuote = async () => {

        setloading(true)

        const resp = await quoteForId(token, id)

        if (resp.ok === true) {
            setorder(resp.data)
            setloading(false)
        } else {
            setorder(null)
            setloading(false)
        }


    }



    const submit = async (status) => {
        if (status === 'Completada' && order.products[0].price === undefined) {
           return toast.error('Debes ingresar precio a los productos de la cotización')
            
        }
       
        setloadingSend(true)
        const data = {

            status: status,
            note: "",
            products: [
                {
                    supplierId: order.supplierId.toString(),
                    restaurantId: order.restaurantId.toString(),
                    productId: order.products[0].productRelatedId.toString(),
                    price: status === 'Rechazada' ? 0 : parseFloat(order.products[0].price),
                }
            ]
        }
        if (status === 'Completada') {
            const resp = await quoteUpdate(token, id, data)
            console.log(resp)
            if (resp.ok) {
                navigate(`/cotizaciones/confirmacion_cotizacion/${order.id}`)
                setloadingSend(false)
            } else {
                setloadingSend(false)
            }
        }else{
            const resp = await quoteUpdate(token, id, data)
            if (resp.ok) {
                toast.success('Cotización rechazada de manera correcta');
                setQuote()
            }
        }   
    }

    const handleOnChange = (index, event) => {
        const newProducts = [...order.products];
        newProducts[index] = {
            ...newProducts[index],
            price: event.target.value, // Agrega o actualiza el precio del producto
        };
        setorder((prev) => ({ ...prev, products: newProducts }));
    };


    return (

        <HomeLayouts>


            {order === null ? Loading :
                <>
                    <PopUp setShowModal={setShowModal} showModal={showModal}>
                        <CancelQuote
                            setShowModal={setShowModal}
                            comerc={ order.nameRestaurant }
                            suppliuer={order.anonymousRestaurant}
                            date={getDisplayDate(order.createdAt)}
                            onClick={() => submit('Rechazada')}
                        />
                    </PopUp>

                    <Grid
                        container
                        minHeight={'100%'}
                        display={'flex'}
                        flexDirection={'row'}
                    >
                        <Grid
                            md={12}
                            xs={12}
                            lg={6}
                            item

                            paddingTop={5}
                        //bgcolor={'white'}

                        >
                            <Box
                                display='flex'
                                flexDirection='row'
                                alignItems={'center'}
                                sx={{ paddingLeft: { xs: 3, sm: 5, md: 10, lg: 8, xl: 14 } }}


                            >
                                <Back style={{ fontSize: 30, marginTop: 5, marginRight: 5 }} onClick={() => navigate('/cotizaciones')} />

                                <Typography
                                    fontWeight={'bold'}
                                    fontSize={28}
                                >
                                    Detalle cotización
                                </Typography>
                            </Box>

                            <Box
                                mt={5}
                                padding={1}
                                display={'flex'}
                                flexDirection={'row'}
                                justifyContent={'center'}

                            >
                                <Grid
                                    container
                                    sx={{ width: { xs: '94%', sm: '88%', md: '76%', lg: '74%' } }}
                                    width={'100%'}
                                    padding={2}
                                    border={1}
                                    borderRadius={3}
                                    borderColor={'#EEEEEE'}
                                >

                                    <Grid
                                        display={'flex'}
                                        flexDirection={'row'}
                                        container

                                        justifyContent={'space-between'}
                                    >
                                        <Grid

                                            xs={3}
                                            md={5}
                                            item
                                        >
                                            <Typography fontWeight={'bold'} >Producto</Typography>
                                        </Grid>
                                        <Grid
                                            xs={3}
                                            md={4}
                                            item

                                        >
                                            <Typography textAlign={'center'} fontWeight={'bold'} >Cantidad mensual</Typography>
                                        </Grid>
                                        {
                                            order && order.status === "Solicitada"  && complet ?
                                                <Grid

                                                    xs={4}
                                                    md={3}
                                                    item
                                                >
                                                    <Typography
                                                        textAlign={'center'}
                                                        fontWeight={'bold'} > {`Precio`}   </Typography>
                                                </Grid> : null
                                        }
                                         {
                                            order && order.status === "Aceptada" || order.status === "Completada" ?
                                                <Grid

                                                    xs={4}
                                                    md={3}
                                                    item
                                                >
                                                    <Typography
                                                        textAlign={'center'}
                                                        fontWeight={'bold'} > {`Precio`}   </Typography>
                                                </Grid> : null
                                        }


                                    </Grid>
                                    {
                                        order && order.products.map((product, index) => {

                                            console.log(order.products[0].price)

                                            return (
                                                <Grid
                                                    key={index}
                                                    mt={1}
                                                    display={'flex'}
                                                    flexDirection={'row'}
                                                    container

                                                    justifyContent={'space-between'}
                                                >
                                                    <Grid
                                                        md={5}
                                                        xs={3}
                                                        item
                                                    >
                                                        <Typography

                                                            sx={{
                                                                overflow: "hidden",
                                                                textOverflow: "ellipsis",
                                                                display: "-webkit-box",
                                                                WebkitLineClamp: "2",
                                                                WebkitBoxOrient: "vertical",
                                                            }}
                                                        >{product.name} </Typography>
                                                    </Grid>
                                                    <Grid
                                                        item
                                                        xs={3}
                                                        md={4}
                                                    >
                                                        <Typography
                                                            textAlign={'center'}
                                                        > {product.quantity} </Typography>
                                                    </Grid>
                                                    {
                                                        order.status === "Solicitada" && complet ?
                                                            <Grid
                                                                md={3}
                                                                xs={4}
                                                                item
                                                            >
                                                                <Box
                                                                    display={'flex'}
                                                                    flexDirection={'row'}

                                                                    alignItems={'center'}
                                                                >
                                                                    <OutlinedInput
                                                                        onChange={(e) => handleOnChange(index, e)}
                                                                        id="outlined-adornment-weight"
                                                                        startAdornment={<InputAdornment sx={{ marginLeft: -1 }} position="start">$</InputAdornment>}
                                                                        size='small'
                                                                        value={product.price === undefined ? '' : product.price}
                                                                        name={product.name}
                                                                        aria-describedby="outlined-weight-helper-text"
                                                                        type='number'
                                                                        inputProps={{
                                                                            style: {
                                                                                paddingRight: 2,
                                                                                height: "10px",
                                                                                minHeight: '10px'
                                                                            },
                                                                        }}
                                                                    />


                                                                    <Typography
                                                                        fontSize={15}
                                                                        mb={0.7}
                                                                        fontWeight={'bold'}
                                                                        textAlign={'center'}
                                                                    > /{order.products.length > 0 ? order.products[0].unitOfMeasure : ''} </Typography>

                                                                </Box>

                                                            </Grid> : null
                                                    }
                                                     {
                                                        order.status != "Solicitada"  ?
                                                            <Grid
                                                                md={3}
                                                                xs={4}
                                                                item
                                                            >
                                                                <Box
                                                                    display={'flex'}
                                                                    flexDirection={'row'}
                                                                   
                                                                    alignItems={'center'}
                                                                    justifyContent={'center'}
                                                                >
                                                                <Typography
                                                                        fontSize={15}
                                                                        mb={0.7}                                                                     
                                                                        textAlign={'center'}
                                                                    > ${product.price} </Typography>

                                                                    <Typography
                                                                        fontSize={15}
                                                                        mb={0.7}                                                                        
                                                                        textAlign={'center'}
                                                                    > /{order.products.length > 0 ? order.products[0].unitOfMeasure : ''} </Typography>

                                                                </Box>

                                                            </Grid> : null
                                                    }


                                                </Grid>

                                            )
                                        })
                                    }

                                </Grid>
                            </Box>

                            <Box
                                mt={3}
                                padding={1}
                                display={'flex'}
                                flexDirection={'column'}
                                alignItems={'center'}

                            >

                                <Box
                                    sx={{ width: { xs: '94%', sm: '88%', md: '76%', lg: '74%' } }}
                                    padding={1}

                                >
                                    <Typography> Notas </Typography>
                                </Box>
                                <Grid
                                    mb={2}
                                    height={80}
                                    container
                                    sx={{ width: { xs: '94%', sm: '88%', md: '76%', lg: '74%' } }}
                                    width={'100%'}
                                    padding={1}
                                    border={1}
                                    borderRadius={3}
                                    borderColor={'#EEEEEE'}
                                >
                                    <Typography
                                        color={'#686D76'}
                                    >
                                        {order && order.note}
                                    </Typography>
                                </Grid>
                            </Box>

                        </Grid>
                        <Grid
                            md={12}
                            xs={12}
                            lg={6}
                            item
                            padding={3}
                            bgcolor={'#f5f5f5'}
                        >


                            <Box
                                sx={{ marginTop: { xs: 2, lg: 11 } }}

                                padding={1}
                                display={'flex'}
                                flexDirection={'row'}
                                justifyContent={'center'}
                            >
                                <Grid
                                    container
                                    sx={{ width: { xs: '94%', sm: '88%', md: '76%', lg: '74%' } }}
                                    width={'100%'}
                                    padding={2}
                                    border={1}
                                    borderRadius={3}
                                    bgcolor={'white'}
                                    borderColor={'#EEEEEE'}
                                    display={'flex'}
                                    flexDirection={'column'}
                                >
                                    <Box
                                        display={'flex'}
                                        flexDirection={'row'}
                                        alignItems={'center'}
                                    >
                                        <Typography color={'#666666'} fontWeight={'bold'} > Estado:  </Typography>
                                        <Box ml={1} paddingBottom={0.1} paddingLeft={1} paddingRight={1} borderRadius={3} bgcolor={order.status === 'Solicitada' ? '#FAECCC' :
                                            order.status === 'Completada' ? '#D6E4EE' : order.status === 'Aceptada' ? '#DEECDC' : '#FAE3DE'} >
                                            <Typography fontSize={13} > {order.status} </Typography>
                                        </Box>
                                    </Box>
                                    <Box
                                        mt={1}
                                        display={'flex'}
                                        flexDirection={'row'}
                                        alignItems={'center'}
                                    >
                                        <Typography color={'#666666'} fontWeight={'bold'} > Cotización:  </Typography>
                                        <Box ml={1} paddingBottom={0.1} paddingLeft={1} paddingRight={1} borderRadius={3} >
                                            <Typography fontSize={16} > {order.id} </Typography>
                                        </Box>
                                    </Box>
                                    <Box
                                        mt={1}
                                        display={'flex'}
                                        flexDirection={'row'}
                                        alignItems={'center'}
                                    >
                                        <Typography color={'#666666'} fontWeight={'bold'} > Comercio:  </Typography>
                                        <Box ml={1} paddingBottom={0.1} paddingLeft={1} paddingRight={1} borderRadius={3} >
                                            <Typography fontSize={16} >{order.anonymousRestaurant ? 'Anonimo' : order.nameRestaurant}</Typography>

                                        </Box>
                                    </Box>



                                </Grid>
                            </Box>
                            <Box
                                mt={1}
                                padding={1}
                                display={'flex'}
                                flexDirection={'row'}
                                justifyContent={'center'}
                            >
                                <Grid
                                    container
                                    sx={{ width: { xs: '94%', sm: '88%', md: '76%', lg: '74%' } }}
                                    width={'100%'}
                                    padding={2}
                                    border={1}
                                    borderRadius={3}
                                    bgcolor={'white'}
                                    borderColor={'#EEEEEE'}
                                    display={'flex'}
                                    flexDirection={'column'}
                                >
                                    <Box
                                        display={'flex'}
                                        flexDirection={'row'}
                                        alignItems={'center'}
                                    >
                                        <Typography color={'#666666'} fontWeight={'bold'} > Fecha de solicitud:  </Typography>
                                        <Box ml={1} paddingBottom={0.1} paddingLeft={1} paddingRight={1} borderRadius={3}>
                                            <Typography fontSize={16} > {moment(order.createdAt).format('DD-MM-YYYY')} </Typography>
                                        </Box>
                                    </Box>

                                </Grid>
                            </Box>

                            {
                                order.status === 'Completada' || order.status === 'Rechazada' || order.status === 'Aceptada' ? null :

                                    <>
                                        <Box
                                            sx={{ marginTop: { xs: -3, md: 0.5, sm: 0.5, lg: 1 } }}
                                            padding={1}
                                            display={'flex'}
                                            flexDirection={'row'}
                                            justifyContent={'center'}
                                        >
                                            <Box
                                                sx={{ width: { xs: '94%', sm: '88%', md: '76%', lg: '74%' } }}
                                                mt={2}
                                            >
                                                <CTA
                                                    isDisabled={loadingSend}
                                                    onClick={() => { !complet ? setComplet(true) : submit('Completada') }}
                                                    text='Completar cotización'

                                                />
                                            </Box>
                                        </Box>

                                        {
                                            complet ? null :
                                                <Box
                                                    sx={{ marginTop: { xs: -3, md: 0.5, sm: 0.5, lg: 1 } }}
                                                    padding={1}
                                                    display={'flex'}
                                                    flexDirection={'row'}
                                                    justifyContent={'center'}
                                                >
                                                    <Box
                                                        sx={{ width: { xs: '94%', sm: '88%', md: '76%', lg: '74%' } }}
                                                        mt={1}
                                                    >
                                                        <CTA
                                                            color='red'
                                                            isDisabled={loadingSend}
                                                            onClick={() => { setShowModal(true) }}
                                                            text='Rechazar cotización'
                                                        />
                                                    </Box>
                                                </Box>
                                        }
                                    </>
                            }



                        </Grid>
                    </Grid>
                </>



            }



        </HomeLayouts>
    )
}


