import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import CTA from 'components/CTA';
import CTARed from '../../../CTARed';
import whatsappIcon from 'assets/whatsapp.svg';
import { getUser } from 'api/user';
import useStore from 'store';

import { changePassword, changeContact } from 'api/user';
import { toast } from 'react-hot-toast';

import { getArrayOfErrors } from 'utils/errors';

import useForm from 'hooks/useForm';
import commonStyles from '../../pop-up.module.scss';
import styles from './profileInfo.module.scss';
import { Typography } from '@mui/material';
import UserRole from '../Users/UsersRole/UserRole';
import UserForm from '../Users/UsersForm/UserForm';
import UserConfirmation from '../Users/UserConfirmation/UserConfirmation'
import { deleteUser, getSubscription } from '../../../../api/user';
import { useDispatch, useSelector } from 'react-redux';
import UserEdit from '../Users/UsersRole/UserEdit';
import { getDisplayDate } from '../../../../utils/dates';
import { setUser } from '../../../../redux/authSlice/authSlice';


const ProfileInfo = ({ subscription, clickedCard, user, setShowModal, row }) => {


  const components = {
    restaurant: <RestaurantInfo />,
    user: <UserInfo user={user} setShowModal={setShowModal} />,
    usersByOwner: <UsersManagement setShowModal={setShowModal} />,
    contact: <ContactMethod user={user} setShowModal={setShowModal} />,
    userDelete: <UserDeleteConfirmation row={row} user={user} setShowModal={setShowModal} />,
    subcription: <Subcription subscription={subscription} user={user} setShowModal={setShowModal} />,
    updateUser: <EditUser userInfo={row} />,
    advertisement: <Advertisement />,
    credentials: <Credentials />
  };
  return components[clickedCard];
};

const RestaurantInfo = () => {
  return <div>RestaurantInfo</div>;
};

const UserInfo = ({ user, setShowModal }) => {

  const { token } = useSelector((state) => state.auth)
  const { formData, handleInputChange, isDisabled } = useForm({
    currentPassword: '',
    newPassword: '',
  });

  const handlePasswordChange = async () => {
    try {
      await changePassword(formData, user?.id, token);
      setShowModal(false);
      toast.success('Contraseña cambiada con éxito');
    } catch (error) {
      if (error.response) {
        const errors = getArrayOfErrors(error.response.data);
        for (let i = 0; i < errors.length; i++) {
          toast.error(errors[i]);
        }
      } else {
        toast.error('Error interno. Inténtelo más tarde');
      }
    }
  };

  return (
    <div className={styles.content}>
      <h1 className={commonStyles.title}>Datos del Usuario</h1>
      <div className={styles.section}>
        <h2 className={styles.sectionTitle}>Tus Datos</h2>
        <input
          disabled={true}
          value={user.name}
          className={`${styles.input} ${styles.disabled}`}
        />
        <input
          disabled={true}
          value={user.phone}
          className={`${styles.input} ${styles.disabled}`}
        />
        <input
          disabled={true}
          value={user.email || 'N/A'}
          className={`${styles.input} ${styles.disabled}`}
        />
      </div>
      <div className={styles.section}>
        <h2 className={styles.sectionTitle}>Cambiar Contraseña</h2>
        <input
          name="currentPassword"
          value={formData.currentPassword}
          className={styles.input}
          onChange={handleInputChange}
          placeholder="Contraseña Actual"
          type="password"
        />
        <input
          name="newPassword"
          value={formData.newPassword}
          className={styles.input}
          onChange={handleInputChange}
          placeholder="Nueva Contraseña"
          type="password"
        />
        <CTA
          text="Cambiar Contraseña"
          onClick={() => handlePasswordChange()}
          isDisabled={isDisabled}
        />
      </div>
    </div>
  );
};

const ContactMethod = ({ user, setShowModal }) => {
  const dispatch = useDispatch()
  const { token } = useSelector((state) => state.auth)
  // const setUser = useStore((state) => state.setUser);
  const [contactMethod, setContactMethod] = useState(user.contactMethod);

  const handleContactChange = async () => {
    try {
      await changeContact({
        contactMethod,
      }, user?.id, token);
      const userf = await getUser(token, user?.phone);
      const subscription = await getSubscription(user?.id, token)
      dispatch(setUser({ user: userf, status: 'authenticated', subscription: subscription.data }))
      setShowModal(false);

      toast.success('Preferencia de contacto actualizada');


      window.location.reload();

    } catch (error) {
      if (error.response) {
        const errors = getArrayOfErrors(error.response.data);
        for (let i = 0; i < errors.length; i++) {
          toast.error(errors[i]);
        }
      } else {
        toast.error('Error interno. Inténtelo más tarde');
      }
    }
  };

  return (
    <div className={styles.content}>
      <h1 className={commonStyles.title}>Preferencia de Contacto</h1>
      <h2 className={commonStyles.subtitle}>
        Por favor selecciona tu preferencia de notificaciones para recibir órdenes de
        compra.
      </h2>
      <div className={styles.body}>
        <div className={styles.card}>
          <div className={styles.cardField}>
            <input
              type="checkbox"
              onChange={() => {
                setContactMethod('phone');
              }}
              checked={contactMethod === 'phone'}
              className={styles.checkbox}
            />
            <div className={styles.label}>Teléfono: </div>
            <div className={styles.value}>{user.phone}</div>
          </div>
          <div className={styles.cardField}>
            <input
              type="checkbox"
              onChange={() => {
                setContactMethod('email');
              }}
              checked={contactMethod === 'email'}
              disabled={user.email === '' || user.email === null}
              className={styles.checkbox}
            />
            <div className={styles.label}>Correo: </div>
            <div className={styles.value}>{user.email}</div>
          </div>
        </div>
        <CTA text="Guardar Cambios" onClick={handleContactChange} />
      </div>
    </div>
  );
};


const Subcription = ({ user, setShowModal, subscription }) => {

  const buttonContent = (
    <>
      <img src={whatsappIcon} alt="whatsapp icon" className={styles.icon} />
      Renovar Suscripción
    </>
  );

  return (

    <div className={styles.content}>
      <h1 className={commonStyles.title}>Suscripción</h1>
      <div className={styles.body}>
        <div className={styles.card}>
          <div className={styles.cardField}>
            <div className={styles.label}>Fecha: </div>
            <div className={styles.value}>
              {subscription ? getDisplayDate(subscription.endDate) : 'N/A'}
            </div>
          </div>
          <div className={styles.cardField}>
            <div className={styles.label}>Facturación: </div>
            <div className={styles.value}>
              {subscription
                ? subscription?.plan?.interval.charAt(0).toUpperCase() +
                subscription?.plan?.interval.slice(1)
                : 'N/A'}
            </div>
          </div>
          <div className={styles.cardField}>
            <div className={styles.label}>Precio: </div>
            <div className={styles.value}>
              {subscription ? `$${subscription.plan.price}` : 'N/A'}
            </div>
          </div>
          <div className={styles.cardField}>
            <div className={styles.label}>Estado: </div>
            {subscription?.status === 'activa' ? (
              <div className={`${styles.statusLabel} ${styles.active}`}>Activo</div>
            ) : (
              <div className={`${styles.statusLabel} ${styles.inactive}`}>Inactivo</div>
            )}
          </div>
        </div>
        {subscription?.status === 'inactive' && (
          <CTA
            text={buttonContent}
            onClick={() => {
              navigate(
                'https://api.whatsapp.com/send/?phone=15409984347&text=¡Hola! Quiero renovar mi suscripción',
              );
            }}
          />
        )}
      </div>
    </div>

  )

}

const UserDeleteConfirmation = ({ row, user, setShowModal }) => {
  const { token } = useSelector((state) => state.auth)
  const [loading, setLoading] = useState(false)



  const handleDeleteUser = async () => {
    setLoading(true)
    try {
      const response = await deleteUser(user.id, row.id, token);

      if (response === 'Usuario eliminado correctamente') {
        toast.success('Usuario eliminado correctamente');

        setTimeout(() => {
          window.location.reload();
          setLoading(false)
        }, 500);

      }
    } catch (error) {
      if (error.response) {
        const errors = getArrayOfErrors(error.response.data);
        for (let i = 0; i < errors.length; i++) {
          toast.error(errors[i]);
        }
        setLoading(false)
      } else {
        toast.error('Error interno. Inténtelo más tarde');
        setLoading(false)
      }
    }
  };

  return (
    <div className={styles.content}>
      <h1 className={commonStyles.title}>Eliminar Usuario</h1>
      <div className={styles.section}>
        <h2 className={styles.titleUser}>Usuario</h2>
        <div className={styles.section}>{row.name}</div>
        <h2 className={styles.titleUser}>Número de teléfono</h2>
        <div className={styles.section}>{row.phone}</div>
        <h2 className={styles.titleUser}>Rol</h2>
        <div className={styles.section}>{row.role}</div>
        <CTARed isDisabled={loading} text="Eliminar" onClick={() => handleDeleteUser()} />
      </div>
    </div>
  );
};



const UsersManagement = ({ setShowModal }) => {
  const [step, setSteep] = useState(0)

  const [state, setState] = useState({
    supplierId: JSON.parse(localStorage.getItem('state'))?.supplierId || null,
    products: JSON.parse(localStorage.getItem('state'))?.products || [],
    note: JSON.parse(localStorage.getItem('state'))?.note || '',
    orderId: JSON.parse(localStorage.getItem('state'))?.orderId || null,
  });
  const [userInfo, setUserInfo] = useState(null);






  switch (step) {
    case 0:
      return <UserForm changeStep={setSteep} setState={setState} setUserInfo={setUserInfo} />;
    case 1:
      return <UserRole changeStep={setSteep} userInfo={userInfo} />;
    case 2:
      return <UserConfirmation setShowModal={setShowModal} />
    default:
      return (
        <Typography>j</Typography>
      );
  }




  // const handleInitialStep = () => {
  //   const storageStep = JSON.parse(localStorage.getItem('step')) || null;
  //   const storageState = JSON.parse(window.localStorage.getItem('state'));

  //   if (
  //     !storageStep ||
  //     typeof storageStep !== 'number' ||
  //     isNaN(storageStep) ||
  //     storageStep > 4 ||
  //     storageStep < 1
  //   ) {
  //     return 1;
  //   }
  //   if (storageStep === 1) {
  //     return 1;
  //   }
  //   if (storageState?.supplierId) {
  //     if (storageStep === 2) return 2;
  //     if (storageState?.products.length > 0) {
  //       if (storageStep === 3) return 3;
  //     }
  //   }
  //   if (storageStep === 4 && storageState?.orderId) {
  //     return 4;
  //   }
  //   return 1;
  // };

  // const [state, setState] = useState({
  //   supplierId: JSON.parse(localStorage.getItem('state'))?.supplierId || null,
  //   products: JSON.parse(localStorage.getItem('state'))?.products || [],
  //   note: JSON.parse(localStorage.getItem('state'))?.note || '',
  //   orderId: JSON.parse(localStorage.getItem('state'))?.orderId || null,
  // });
  // const [userInfo, setUserInfo] = useState(null);

  // const [step, setStep] = useState(handleInitialStep());

  // const changeStep = (direction) => {
  //   if (direction === 'next') {
  //     setStep(step + 1);
  //   }
  //   if (direction === 'prev') {
  //     setStep(step - 1);
  //   }
  // };

  // useEffect(() => {
  //   window.localStorage.setItem('state', JSON.stringify(state));
  // }, [state]);

  // useEffect(() => {
  //   window.localStorage.setItem('step', JSON.stringify(step));
  // }, [step]);

  // switch (step) {
  //   case 2:
  //     return <UserRole changeStep={changeStep} setState={setState} userInfo={userInfo} />;
  //   case 3:
  //     return <UserConfirmation setShowModal={setShowModal} />;
  //   default:
  //     return (
  //       <UserForm changeStep={changeStep} setState={setState} setUserInfo={setUserInfo} />
  //     );
  // }
};



const EditUser = ({ userInfo }) => {

  return (

    <UserEdit userInfo={userInfo} />


  )
}


const Advertisement = ({ user, setShowModal, subscription }) => {
  const { formData, handleInputChange, isDisabled } = useForm({
    advertisement: '',
  });





  return (

    <div className={styles.content}>
      <h1 className={commonStyles.title}>Anuncio</h1>
      <h2 className={commonStyles.subtitle}>
      El texto que ingreses en el campo "Anuncio" se mostrará en la parte superior de la pantalla de tus clientes a la hora de generar un pedido. 
      Asegúrate de escribir la información que deseas que el cliente vea al realizar su pedido.
      </h2>
      {/* <div className={styles.body}>
      <div className={styles.card}>
        <div className={styles.cardField}>
          <div className={styles.label}>Fecha: </div>
          <div className={styles.value}>
            {subscription ? getDisplayDate(subscription.endDate) : 'N/A'}
          </div>
        </div>
        <div className={styles.cardField}>
          <div className={styles.label}>Facturación: </div>
          <div className={styles.value}>
            {subscription
              ? subscription?.plan?.interval.charAt(0).toUpperCase() +
              subscription?.plan?.interval.slice(1)
              : 'N/A'}
          </div>
        </div>
        <div className={styles.cardField}>
          <div className={styles.label}>Precio: </div>
          <div className={styles.value}>
            {subscription ? `$${subscription.plan.price}` : 'N/A'}
          </div>
        </div>
        <div className={styles.cardField}>
          <div className={styles.label}>Estado: </div>
          {subscription?.status === 'activa' ? (
            <div className={`${styles.statusLabel} ${styles.active}`}>Activo</div>
          ) : (
            <div className={`${styles.statusLabel} ${styles.inactive}`}>Inactivo</div>
          )}
        </div>
      </div>
      {subscription?.status === 'inactive' && (
        <CTA
          text={buttonContent}
          onClick={() => {
            navigate(
              'https://api.whatsapp.com/send/?phone=15409984347&text=¡Hola! Quiero renovar mi suscripción',
            );
          }}
        />
      )}
    </div> */}
 
      <textarea
        name='advertisement'
        className={commonStyles.note}
        rows="4"
        maxLength="250"
        value={formData.advertisement}
        onChange={handleInputChange} />
      <CTA text="Guardar Cambios" onClick={() => console.log()} />
    </div>

  )

}

const Credentials = ({ user, setShowModal, subscription }) => {
  const { formData, handleInputChange, isDisabled } = useForm({
    api: '',
  });



  return (

    <div className={styles.content}>
      <h1 className={commonStyles.title}>API KEI</h1>
      <h2 className={commonStyles.subtitle}>
        Ingresa el api key de Contifico.
      </h2>
      <input
          name="api"
          value={formData.newPassword}
          className={styles.input}
          onChange={handleInputChange}
          placeholder="Api key"
        
        />
    
      <CTA text="Guardar Cambios" onClick={() => console.log()} />
    </div>

  )

}
ProfileInfo.propTypes = {
  clickedCard: PropTypes.string,
  setShowModal: PropTypes.func.isRequired,
  subscription: PropTypes.object,
};

UserInfo.propTypes = {
  user: PropTypes.object.isRequired,
  setShowModal: PropTypes.func.isRequired,
};

ContactMethod.propTypes = {
  user: PropTypes.object,
  setShowModal: PropTypes.func.isRequired,
};

export default ProfileInfo;
